<template>
  <div class="upload">
    <!-- <el-page-header @back="goBack" content="详情页面"></el-page-header> -->
    <div class="upload-group">
      <h3>图片<span>（最多可上传9张图片）</span></h3>
      <ul class="el-upload-list el-upload-list--picture-card">
        <li class="el-upload-list__item is-success" v-for="(file, index) of imgList" :key="index">
          <div style="width: 100%;height:100%;">
            <div class="video-wrap" @click="swiperShow(index,'img')">
              <el-image :src="file.url" fit="contain"></el-image>
            </div>
            <img class="upload-del" v-if="type == 'upload'" src="@/assets/upload/del_icon.png" @click="delImage(index)"
              alt="" />
          </div>
        </li>
      </ul>
      <template v-if="type != 'watch'">
        <el-upload action="#" list-type="picture-card" multiple :show-file-list="false" accept="image/*"
          :auto-upload="false" :on-change="selectImage" v-if="imgList.length < 9">
          <i class="el-icon-plus"></i>
        </el-upload>
      </template>
    </div>
    <div class="upload-group">
      <h3>视频<span>（视频可上传1-3个，支持MP4、MOV格式）</span></h3>
      <ul class="el-upload-list el-upload-list--picture-card">
        <li class="el-upload-list__item is-success" v-for="(file, index) of videoList" :key="index">
          <div style="width: 100%;height:100%;" class="video_bg">
            <div class="video-wrap" @click="swiperShow(index,'video')">
              <video :src="file.url" style="width: 100%;"></video>
              <img class="upload-icon" src="@/assets/upload/play_icon.png" alt="">
            </div>
            <img class="upload-del" v-if="type == 'upload'" src="@/assets/upload/del_icon.png" @click="delVideo(index)"
              alt="" />
          </div>
        </li>
      </ul>
      <template v-if="type != 'watch'">
        <el-upload action="#" multiple list-type="picture-card" accept="video/*" :show-file-list="false"
          :auto-upload="false" :on-change="selectVideo" v-if="videoList.length < 3">
          <i class="el-icon-plus"></i>
        </el-upload>
      </template>
    </div>
    <div class="upload-group filelist-group">
      <h3>文档/压缩包<span>（可上传1-6个文档或压缩包，文档支持Word、Excel、PPT、PDF格式）</span></h3>
      <ul class="el-upload-list el-upload-list--picture-card">
        <li class="el-upload-list__item is-success" v-for="(file, index) of fileList" :key="index">
          <div class="file-item">
            <span class="line1">{{ file.file_name || file.name }}</span>
            <i class="el-icon-delete" v-if="type == 'upload'" @click="delFile(index)"></i>
          </div>
        </li>
      </ul>
      <template v-if="type != 'watch'">
        <el-upload action="#" multiple list-type="picture-card"
          accept="application/msword, application/pdf, .zip,.rar,application/x-rar-compressed,application/zip,application/x-zip-compressed,application/octet-stream, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .docx, .doc, .ppt, .pptx"
          :auto-upload="false" :show-file-list="false" :on-change="selectFile" v-if="fileList.length < 6">
          <i class="el-icon-plus"></i>
        </el-upload>
      </template>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="100%">
      <!-- <swiper :options="swiperOption" :auto-update="true" :auto-destroy="true" ref="Swiper">
      <swiper-slide v-for="(item,index) of swiperData" :key="index">
        <div class="inner">
          <div class="inner-box">
            <el-image v-if="swiperType == 'img'" width="20rem" height="20rem" :src="item.url" fit="contain"></el-image>
            <video controls autoplay v-else :src="item.url"></video>
          </div>
        </div>
      </swiper-slide>
    </swiper> -->
      <div class="inner" v-if="swiperData.length">
        <div class="inner-box">
          <el-image v-if="swiperType == 'img'" width="20rem" height="20rem" :src="swiperData[swiperOption.initialSlide].url" fit="contain"></el-image>
          <video controls autoplay v-else :src="swiperData[swiperOption.initialSlide].url"></video>
        </div>
      </div>
    </el-dialog>
    <div class="upload-footer" v-if="type != 'watch'">
      <el-button class="upload-btn" @click="upload" type="primary">确认上传</el-button>
    </div>
  </div>
</template>

<script>
import obs from "@/utils/obs.js";
import {
  Swiper,
  SwiperSlide,
  directive
} from "vue-awesome-swiper"
import "swiper/css/swiper.css";

export default {
  name: "upload",
  data() {
    return {
      type: '',
      pageType: '',
      dialogTitle: "",
      inspect_id: '',
      object_pid: '',
      object_id: '',
      swiperOption: {
        // 设定初始化时slide的索引
        initialSlide: 0,
        direction: "horizontal",
        on: {
          slideChange: function () {
            this.activeName = this.activeIndex.toString()
          }
        }
      },
      swiperType: '',
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      swiperData: '',
      imgList: [],
      videoList: [],
      fileList: [],
      loading: {
        lock: true,
        text: "正在上传",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },
      uploadLength: 0,
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  mounted() {
    console.log();
    this.inspect_id = this.$route.query.inspect_id;
    this.object_pid = this.$route.query.object_pid;
    this.object_id = this.$route.query.object_id;
    this.type = this.$route.query.type;
    this.pageType = this.$route.query.pageType
    // let files = sessionStorage.getItem('files') && JSON.parse(sessionStorage.getItem('files'))
    let files = [];
    if (sessionStorage.getItem('files') != 'undefined') {
      files = JSON.parse(sessionStorage.getItem('files'))
    }
    if (files.length) {
      files.forEach(e => {
        e.api_url = e.url.replace('https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/', '')
        e.name = e.file_name;
      })
      this.imgList = files.filter(e => {
        return e.file_type == 1
      });
      this.videoList = files.filter(e => {
        return e.file_type == 2
      });
      this.fileList = files.filter(e => {
        return e.file_type == 3
      });
    }

  },
  methods: {
    swiperShow(index, type) {
      this.swiperType = type;
      this.swiperOption.initialSlide = index;
      this.swiperData = (type == 'img') ? this.imgList : this.videoList;
      this.dialogVisible = true;
    },
    async selectImage(file, fileList) {
      // console.log(file, fileList)
      if (this.imgList.length >= 9) {
        this.$message.warning(`当前限制选择 9 个图片`);
      } else {
        let loadingService = this.$loading(this.loading);
        let pahtName = `yili_inspect/inspect_${this.inspect_id}/pid_${this.object_pid}/id_${this.object_id}`;
        let bucketName = 'servicetuoyusaas';
        if (file.raw) {
          file = file.raw
          file.file_name = file.name;
          let fileName = pahtName + '/' + this.getFileDate() + '_' + file.file_name;
          let res = await obs.upload(file, fileName, bucketName);
          loadingService.close();
          let url =
            "https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/" + fileName;
          if (res == 'success') {
            if (this.imgList.length < 9) {
              this.imgList.push({
                name: file.name,
                url: url,
                api_url: fileName
              });
            }
          }
        }
      }
    },
    async selectVideo(file, fileList) {
      // console.log(file, fileList)
      if (this.videoList.length >= 3) {
        this.$message.warning(`当前限制选择 3 个视频`);
      } else {
        let loadingService = this.$loading(this.loading);
        let pahtName = `yili_inspect/inspect_${this.inspect_id}/pid_${this.object_pid}/id_${this.object_id}`;
        let bucketName = 'servicetuoyusaas';
        if (file.raw) {
          file = file.raw
          file.file_name = file.name;
          let fileName = pahtName + '/' + this.getFileDate() + '_' + file.file_name;
          let res = await obs.upload(file, fileName, bucketName);
          loadingService.close();
          let url =
            "https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/" + fileName;
          if (res == 'success') {
            if (this.videoList.length < 3) {
              this.videoList.push({
                name: file.name,
                url: url,
                api_url: fileName
              });
            }
          }
        }
      }
    },
    async selectFile(file, fileList) {
      if (this.fileList.length >= 6) {
        this.$message.warning(`当前限制选择 6 个文件`);
      } else {
        let loadingService = this.$loading(this.loading);
        let pahtName = `yili_inspect/inspect_${this.inspect_id}/pid_${this.object_pid}/id_${this.object_id}`;
        let bucketName = 'servicetuoyusaas';
        if (file.raw) {
          file = file.raw
          file.file_name = file.name;
          let fileName = pahtName + '/' + this.getFileDate() + '_' + file.file_name;
          let res = await obs.upload(file, fileName, bucketName);
          loadingService.close();
          let url =
            "https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/" + fileName;
          if (res == 'success') {
            if (this.fileList.length < 6) {
              this.fileList.push({
                name: file.name,
                url: url,
                api_url: fileName
              });
            }
          }
        }
      }
    },
    delImage(index) {
      console.log(index)
      this.imgList.splice(index, 1);
    },
    delVideo(index) {
      this.videoList.splice(index, 1);
    },
    delFile(index) {
      this.fileList.splice(index, 1);
    },
    handleClose() { },
    getFileDate() {
      let date = new Date();
      return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getTime()
    },
    async upload() {
      let files_json = [];
      for (const iterator of this.imgList) {
        files_json.push({
          url: iterator.api_url,
          file_type: 1,
          file_name: iterator.name
        })
      }
      for (const iterator of this.videoList) {
        files_json.push({
          url: iterator.api_url,
          file_type: 2,
          file_name: iterator.name
        })
      }
      for (const iterator of this.fileList) {
        files_json.push({
          url: iterator.api_url,
          file_type: 3,
          file_name: iterator.name
        })
      }
      if (files_json.length == 0) {
        this.$router.go(-1);
        return;
      }
      let res = await this.$api({
        method: "post",
        url: "/api/inspect/files_save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          object_id: this.object_id,
          result_json: files_json.length ? JSON.stringify(files_json) : []
        }
      });
      if (res.code == 0) {
        console.log('保存文件成功', files_json)
        this.$message(
          '上传成功',
        )
        setTimeout(() => {
          this.$router.go(-1);
        }, 300);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  min-height: 100vh;
  padding-bottom: 120px;
  background: #f7f8fa;

  .video_bg {
    background: url('../../assets/index/video_bg.png') no-repeat center;
    background-size: 100% 100%;
  }

  .upload-del {
    position: absolute;
    right: -9px;
    top: -11px;
    width: 22px;
    height: 22px;
  }

  .upload-footer {
    width: 100vw;
    height: 76px;
    background: #ffffff;
    box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-btn {
      width: 323px;
    }
  }

  .file-item {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-size: 15px;
    padding: 20px 15px;
    width: 100%;

    span {
      flex: auto;
    }

    i {
      flex: 0 0 20px;
    }
  }

  .upload-group {
    overflow: hidden;
    padding: 0 15px;
    text-align: left;
  }

  h3 {
    font-size: 18px;
    color: #333;
  }

  h3 span {
    font-size: 12px;
    color: #666;
    font-weight: normal;
  }
}
</style>
<style lang="scss">
.upload {
  .max-length {
    .el-upload.el-upload--picture-card {
      display: none;
    }
  }

  .filelist-group .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 1 100%;
    height: 30px;
    width: 345px;
    background: #ffffff;
    padding: 20px 0;
  }

  .filelist-group .el-upload--picture-card {
    height: 56px;
  }

  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 0 106px;
    width: 106px;
    height: 106px;
    display: flex;
    float: left;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
    border-width: 1px;
  }

  .el-upload-list--picture-card .el-upload-list__item {
    overflow: visible;
    border: none;
  }

  .video-wrap {
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-image {
      width: 100%;
      height: 100%;
    }

    .upload-icon {
      width: 39px;
      height: 39px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .el-upload--picture-card {
    margin-right: 0;
  }

  .el-upload-list--picture-card .el-upload-list__item:nth-of-type(3n) {
    margin-right: 0;
  }

  .swiper-wrapper {
    height: 100%;
  }

  .el-dialog {
    height: 100vh;
    width: 100vw;
    margin: 0 !important;
    background: rgba(242, 242, 242, 0);
    box-shadow: none;
    overflow: hidden;
    box-sizing: border-box;

    .el-dialog__header {
      padding: 30px;
    }

    .el-dialog__close {
      font-size: 30px;
      color: #fff;
      box-sizing: border-box;
    }

    .el-dialog__body {
      height: calc(100% - 60px);
      box-sizing: border-box;

      .swiper-container {
        height: 100%;

        .inner {
          height: 100%;

          .inner-box {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 150px;

            video {
              width: 100%;
              height: 250px;
              background: #000;
            }
          }
        }
      }
    }
  }

  .swiper-slide {
    height: 100%;
  }
}
</style>
